<template>
  <el-dialog
    v-model="dialogFormVisible"
    :title="title"
    width="600px"
    height="600px"
    @close="handleClose"
  >
    <el-form
      label-width="150"
      ref="formRef"
      :model="state.form"
      :rules="state.rules"
    >
      <el-form-item label="门票选择" prop="ticketCode">
        <el-select
          class="w-percent-100 m-2"
          v-model="state.ticketSelect"
          placeholder="请选择要关联的门票"
          @change="handleChangeSelect"
        >
          <el-option
            v-for="item in state.list"
            :key="item.ticketCode"
            :label="item.value"
            :value="item.ticketCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="门票名称" prop="ticketName">
        <el-input
          v-model="state.form.ticketName"
          placeholder="请输入门票名称"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click.stop="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleSave">确认编辑</el-button>
    </template>
  </el-dialog>
</template>

<script setup>
  import { reactive, ref, getCurrentInstance } from 'vue'
  import { updateTicketCategoryRef } from '@/api/exhibitionManage'
  import { debounce } from '@/utils/debounce'
  import Bus from '@/utils/bus'
  const props = defineProps({
    title: {
      type: String,
      default: '编辑',
    },
  })
  const { proxy } = getCurrentInstance()
  const dialogFormVisible = ref(false)
  const formRef = ref(null)
  const state = reactive({
    ticketSelect: '',
    form: {
      id: 0,
      ticketCategoryId: 0,
      ticketCode: '',
      ticketName: '',
    },
    list: [],
    rules: {
      ticketName: [
        {
          required: true,
          trigger: 'blur',
          message: '请输入门票名称',
        },
      ],
      ticketCode: [
        {
          required: true,
          trigger: 'blur',
          message: '请选择要关联的门票',
        },
      ],
    },
  })
  const emit = defineEmits(['noticeRefresh'])
  // 关闭
  const handleClose = () => {
    formRef.value.resetFields()
    state.ticketSelect = ''
    dialogFormVisible.value = false
  }
  // 打开
  const handleOpen = (row = state.form, list) => {
    console.log('list===11111111111', list)
    state.form = row
    state.list = list
    dialogFormVisible.value = true
  }
  // 下拉事件
  const handleChangeSelect = (e) => {
    state.form.ticketCode = e
    let item = state.list.find((item) => item.ticketCode == e)
    state.ticketSelect = item.value || ''
  }
  // 保存
  const handleSave = debounce(() => {
    formRef.value.validate(async (valid) => {
      if (valid) {
        let resp = await updateTicketCategoryRef(state.form)
        proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
        Bus.$emit('closeDrawer1')
        Bus.$emit('noticeRefresh')
        handleClose()
      }
    })
  })
  defineExpose({
    handleClose,
    handleOpen,
  })
</script>
